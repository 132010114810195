import angular from "angular";

export default function XucDirectory($rootScope, XucPhoneHintService, XucLink, $log, $q, XucVideoEventManager, Broadcaster) {
  var searchResult = [];
  var contactSheets = [];
  var favoriteContactSheets = [];
  var favorites = [];
  var headers = [];
  var searchTerm = null;
  var isSearching = false;
  var displayNameRequests = new Map();
  var displayNamesCache = new Map();

  var _directoryLookup = function(term) {
    searchTerm = term ? term : '';
    isSearching = true;
    Cti.directoryLookUp(searchTerm);
  };

  var _contactLookup = function(term) {
    searchTerm = term ? term : '';
    isSearching = true;
    Cti.searchContacts(searchTerm);
  };

  var _getSearchTerm = function() {
    return searchTerm;
  };

  var _isSearching = function() {
    return isSearching;
  };

  var _onSearchResult = function(result) {
    searchResult = angular.copy(result.entries);
    isSearching = false;
    _setHeaders(result);
    $rootScope.$broadcast('searchResultUpdated');
  };

  var _onContactSheet = function(result) {
    contactSheets = angular.copy(result.sheets);
    isSearching = false;
    $rootScope.$broadcast('contactSheetUpdated');
  };

  var _onFavoriteContactSheet = function(result) {
    favoriteContactSheets = angular.copy(result.sheets);
    isSearching = false;
    $rootScope.$broadcast('favoriteContactSheetUpdated');
  };

  var _onFavorites = function(newFavorites) {
    searchResult = [];
    favorites = angular.copy(newFavorites.entries);
    _setHeaders(newFavorites);
    $rootScope.$broadcast('favoritesUpdated');
  };

  var _onUserDisplayNameResult = function(result) {
    if (result.displayName) {
      if (displayNameRequests.has(result.userName)) {
        displayNamesCache.set(result.userName, result.displayName);
        displayNameRequests.get(result.userName).resolve(result.displayName);
        displayNameRequests.delete(result.userName);
      }
      else {
        $log.error("Unknown result received, no such username in the map", result.userName);
      }
    }
    else {
      $log.error("Error when retrieving display name ", result);
      displayNameRequests.forEach((values) => {
        values.reject('Failure');
      });
      displayNameRequests.clear();
    }
  };

  var _setHeaders = function(result) {
    headers = angular.copy(result.headers);
    if(headers.indexOf("Favoris") != -1) {
      headers.splice(-3, 2);
    }
  };

  var _onFavoriteUpdated = function(update) {
    if (update.action === "Removed" || update.action === "Added") {
      contactSheets.forEach(function (elem) {
        if (elem.sources[0].id === update.contact_id && elem.sources[0].name === update.source) {
          elem.isFavorite = ! elem.isFavorite;
        }
      });
    }
    else {
      $log.log("SERVER ERROR: Favorite update failed: ", update);
    }
    _updateFavorites(update);
    $rootScope.$broadcast('searchResultUpdated');
  };

  var _updateFavorites = function(update) {
    if (update.action === "Removed") {
      var result = _find(favoriteContactSheets, update);
      result.elem.isFavorite = false;
      if (_find(contactSheets, update) === undefined) contactSheets.push(result.elem);
      if (result.index >= 0) favoriteContactSheets.splice(result.index, 1);
    }
    else if (update.action == "Added") {
      var newFavorite = _find(contactSheets, update);
      if (newFavorite) {
        newFavorite.elem.isFavorite = true;
        favoriteContactSheets.push(newFavorite.elem);
      }
    }
    $rootScope.$broadcast('favoritesUpdated');
    $rootScope.$broadcast('favoriteContactSheetUpdated');
  };

  var _find = function(contacts, update) {
    var result;
    contacts.forEach(function(elem, i) {
      if (elem.sources[0].id === update.contact_id && elem.sources[0].name === update.source) {
        result = {'elem': elem, 'index': i};
      }
    });
    return result;
  };

  var _getSearchResult = function() {
    return searchResult;
  };

  var _getContactSheetResult = function() {
    return contactSheets;
  };

  var _getFavoriteContactSheet = function() {
    return favoriteContactSheets;
  };

  var _getFavorites = function() {
    return favorites;
  };

  var _clearResults = function() {
    favorites = [];
    searchResult = [];
    searchTerm = null;
    isSearching = false;
    $rootScope.$broadcast('searchResultUpdated');
  };

  var _getHeaders = function() {
    return headers;
  };

  const isNotEmpty = array => array.length > 0;
 
  /** Update the status of a contact in a list (searchResult or favorites) **/

  let _updateStatus = function(event, entry, statusType) {
    let source = null;
    let sourceEvent = "";
    let matchFound = false;

    if (isNotEmpty(searchResult)) {
      source = searchResult;
      sourceEvent = 'searchResultUpdated';
    } 
    else if(isNotEmpty(favorites)) {
      source = favorites;
      sourceEvent = 'favoritesUpdated';
    } else return;

    angular.forEach(source, function(contact) {
      if (angular.isDefined(contact) &&
          angular.isArray(contact.entry) &&
          contact[entry] === event.fromUser)
      {
        contact[statusType] = event.status;
        matchFound = true;
      }
    });

    if (matchFound) {
      Broadcaster.send(sourceEvent);
    }
  };
  
  let _updatePhoneHintStatus = function(phoneHint) {
    _updateStatus(phoneHint, 'entry[1]', 'status');
  };

  let _updateVideoEventStatus = function(videoEvent) {
    _updateStatus(videoEvent, 'username', 'videoStatus');
  };
   


  /** Update the status of a contact in a list (contactSheet or favoriteContactSheet) **/
 
  let _updateContactStatus = (list, event, actionType, statusType, eventProperty, sourceEvent) => {
    list.forEach(contact => {
      if (contact.actions[actionType] && 
        isNotEmpty(contact.actions[actionType].args) && 
          contact.actions[actionType].args[0] === event[eventProperty]) {
        contact.status[statusType] = event.status;
        Broadcaster.send(sourceEvent);
      }
    });
  };

  let _updateContactPhoneHintStatus = function(list, phoneHint, sourceEvent) {
    _updateContactStatus(list, phoneHint, 'Call', 'phone', 'number', sourceEvent);
  };

  let _updateContactVideoEventStatus = function(list, videoEvent, sourceEvent) {
    _updateContactStatus(list, videoEvent, 'Video', 'video', 'fromUser', sourceEvent);
  };



  let _onEvent = function(event, updateContactStatusFunc, updateStatusFunc) {
    if (isNotEmpty(contactSheets)) {
      updateContactStatusFunc(contactSheets, event, 'contactSheetUpdated');
    } 
    if(isNotEmpty(favoriteContactSheets)) {
      updateContactStatusFunc(favoriteContactSheets, event, 'favoriteContactSheetUpdated');
    } else {
      updateStatusFunc(event);
    } 
  };
  
  let _onPhoneHint = function(phoneHint) {
    _onEvent(phoneHint, _updateContactPhoneHintStatus, _updatePhoneHintStatus);
  };

  let _onVideoEvent = function(videoEvent) {
    _onEvent(videoEvent, _updateContactVideoEventStatus, _updateVideoEventStatus);
  };



  var _getUserDisplayName = (user) => {
    var deferred = $q.defer();
    if (displayNamesCache.has(user)) {
      deferred.resolve(displayNamesCache.get(user));
    }
    else
    {
      displayNameRequests.set(user, deferred);
      Cti.displayNameLookup(user);
    }
    return deferred.promise;
  };

  XucPhoneHintService.addEventListener($rootScope, _onPhoneHint);
  XucVideoEventManager.subscribeToVideoStatusEvent($rootScope,_onVideoEvent);

  const _init = () => {
    $log.info("Starting XucDirectory service");
    Cti.setHandler(Cti.MessageType.DIRECTORYRESULT, _onSearchResult);
    Cti.setHandler(Cti.MessageType.CONTACTSHEET, _onContactSheet);
    Cti.setHandler(Cti.MessageType.FAVORITECONTACTSHEET, _onFavoriteContactSheet);
    Cti.setHandler(Cti.MessageType.FAVORITES, _onFavorites);
    Cti.setHandler(Cti.MessageType.FAVORITEUPDATED, _onFavoriteUpdated);
    Cti.setHandler(Cti.MessageType.USERDISPLAYNAME, _onUserDisplayNameResult);
    XucLink.whenLoggedOut().then(_unInit);
  };

  const _unInit = () => {
    $log.info("Unloading XucDirectory service");
    displayNameRequests.clear();
    displayNamesCache.clear();
    XucLink.whenLogged().then(_init);
  };

  XucLink.whenLogged().then(_init);

  return {
    directoryLookup : _directoryLookup, // deprecated
    contactLookup : _contactLookup,
    onSearchResult : _onSearchResult,
    onContactSheet : _onContactSheet,
    onFavoriteContactSheet : _onFavoriteContactSheet,
    onFavorites : _onFavorites,
    onFavoriteUpdated : _onFavoriteUpdated,
    getSearchResult : _getSearchResult, // deprecated
    getContactSheetResult : _getContactSheetResult,
    getFavorites : _getFavorites,
    clearResults: _clearResults,
    getHeaders : _getHeaders,
    getSearchTerm : _getSearchTerm,
    getFavoriteContactSheet : _getFavoriteContactSheet,
    isSearching : _isSearching,
    getUserDisplayName: _getUserDisplayName,
  };
}
